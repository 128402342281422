<template>
  <header ref="header">
    <b-container>
      <div class="headernav" ref="headernav">
        <NuxtLink to="/" class="logo">
          <img :src="'/img/' + logo" :alt="title" />
        </NuxtLink>
      </div>
      <div class="headerinfo">
        <picture>
          <source media="(min-width: 768px)" :data-srcset="require('~/assets/img/' + headerimg)"/>
          <source media="(min-width: 320px)" :data-srcset="require('~/assets/img/' + headerimgMobile)"/>
          <img class="lazyload" :src="require('~/assets/img/' + headerimg)" :data-src="require('~/assets/img/' + headerimg)" :alt="title"/>
        </picture>
      </div>
    </b-container>
  </header>
</template>

<script>

  import { elementExisits } from '~/assets/js/utils';
  import bookingForm from "~/components/content/forms/bookingForm.vue";

  export default {
    components: {
      bookingForm
    },

    props: ['activeTheme'],

    data() {
      return {
        activeSection: 'fewo',
        activeSectionScroll: '',
        selectTheme: this.$props.activeTheme,
        logo: this.$store.state.fewoDatas[this.$props.activeTheme][0].logo,
        title: this.$store.state.fewoDatas[this.$props.activeTheme][0].logotitle,
        headerimgMobile: this.$store.state.fewoDatas[this.$props.activeTheme][0].headerimgMobile,
        headerimg: this.$store.state.fewoDatas[this.$props.activeTheme][0].headerimg,
        scrollOffset: 0,
      }
    },

    mounted: function(){
      if (elementExisits(this.$refs.header)) {
        this.onResize();
        window.addEventListener('resize', this.onResize);
        window.addEventListener('scroll', this.onStickyHeader);
      }
    },

    methods: {
      onResize() {
          this.header = this.$refs.header;
          this.onStickyHeader();
      },

      onStickyHeader() {
        if(this.header) {
          if (window.pageYOffset > 10) {
            this.header.classList.add("sticky");
          } else {
            if (this.header.classList.contains("sticky")) {
              this.header.classList.remove("sticky");
            }
          }
        }

      }
    }
  }
</script>