<template>
  <header ref="header">
    <b-container>
      <div class="headernav" ref="headernav">
        <NuxtLink to="/" class="logo">
          <img :src="'/img/' + logo" :alt="title" />
        </NuxtLink>
        <scrollactive class="my-nav" :offset="scrollOffset">
          <a href="#fewo" class="scrollactive-item fewo">Ferienwohnung</a>
          <a href="#prices" class="scrollactive-item prices">Preise</a>
          <a href="#location" class="scrollactive-item location">Lage & Anfahrt</a>
          <a href="#contact" class="scrollactive-item contact">Kontakt</a>
        </scrollactive>
      </div>
      <div class="headerinfo">
        <picture>
          <source media="(min-width: 768px)" :srcset="require('~/assets/img/' + headerimg + '?resize&size=1000').src + ' 1x,' + require('~/assets/img/' + headerimg + '?resize&resize').src + ' 2x'"/>
          <source media="(min-width: 450px)" :srcset="require('~/assets/img/' + headerimgMobile + '?resize&size=768').src + ' 1x, ' + require('~/assets/img/' + headerimgMobile + '?resize&size=1536').src + ' 2x'"/>
          <source media="(min-width: 320px)" :srcset="require('~/assets/img/' + headerimgMobile + '?resize&size=450').src + ' 1x, ' + require('~/assets/img/' + headerimgMobile + '?resize&size=900').src + ' 2x'"/>
          <img :src="require('~/assets/img/' + headerimg + '?resize&size=1000').src" :alt="title"/>
        </picture>
        <div class="infobox" id="priceinfo" ref="priceinfo">
          <div class="text-wrapper">
            <p class="headline">
              {{ headline }}
            </p>
            <p class="description">
              {{ description }}
            </p>
            <p class="priceinfo">
              ab <span>{{ price }}</span> / Nacht
            </p>
          </div>
          <bookingForm :selectTheme="selectTheme" :selectFewo="selectFewo"/>
        </div>
      </div>
    </b-container>
  </header>
</template>

<script>

  import { getWindowInnerWidth, elementExisits } from '~/assets/js/utils';
  import { breakpoints } from '~/assets/js/variables';

  import bookingForm from "~/components/content/forms/bookingForm.vue";

export default {
  components: {
    bookingForm
  },

  props: ['activeTheme','activeFewo'],

  data() {
    return {
      activeSection: 'fewo',
      activeSectionScroll: '',
      selectTheme: this.$props.activeTheme,
      selectFewo: this.$props.activeFewo,
      logo: this.$store.state.fewoDatas[this.$props.activeTheme][0].logo,
      title: this.$store.state.fewoDatas[this.$props.activeTheme][0].logotitle,
      headline: this.$store.state.fewoDatas[this.$props.activeTheme][0].headline,
      description: this.$store.state.fewoDatas[this.$props.activeTheme][0].description,
      headerimgMobile: this.$store.state.fewoDatas[this.$props.activeTheme][0].headerimgMobile,
      headerimg: this.$store.state.fewoDatas[this.$props.activeTheme][0].headerimg,
      price: this.$store.state.fewoDatas[this.$props.activeTheme][0].price,
      scrollOffset: 0,
    }
  },

  mounted: function(){
    if (elementExisits(this.$refs.header) && elementExisits(this.$refs.headernav) && elementExisits(this.$refs.priceinfo)) {
      this.onResize();
      window.addEventListener('resize', this.onResize);
      window.addEventListener('scroll', this.onResize);
    }
  },

  methods: {
    onResize() {
      this.header = this.$refs.header;

      this.headerNav = this.$refs.headernav;
      this.headerNavHeight = this.headerNav.offsetHeight + 3;

      if(window.innerWidth >= 1000) {
        this.headerNavHeight = this.headerNav.offsetHeight;
      }

      this.headerInfo = this.$refs.priceinfo;
      this.headerinfoHeight = this.headerInfo.offsetHeight;

      if(!this.headerInfo.classList.contains('fixed')) {
        this.headerInfoPosition = this.headerInfo.offsetTop;
      }

      this.positionPriceinfo = this.headerInfoPosition - this.headerNavHeight;
      this.scrollOffset = this.headerNavHeight + this.headerinfoHeight + 20;

      this.onStickyHeader();
    },

    onStickyHeader() {
      if (window.pageYOffset > 10) {
        this.header.classList.add("sticky");
      } else {
        if (this.header.classList.contains("sticky")) {
          this.header.classList.remove("sticky");
        }
      }

      if(getWindowInnerWidth() >= breakpoints.breakpoint768) {
        if (window.pageYOffset > this.positionPriceinfo) {
          this.headerInfo.classList.add("fixed");
          this.headerInfo.setAttribute("style", "top: "+this.headerNavHeight+"px");
          this.headerInfo.parentNode.setAttribute("style", "padding-bottom: "+this.headerinfoHeight+"px");
        } else {
          if (this.headerInfo.classList.contains("fixed")) {
            this.headerInfo.classList.remove("fixed");
          }
          this.headerInfo.setAttribute("style", "top: 0px");
          this.headerInfo.parentNode.setAttribute("style", "padding-bottom: 0px");
        }
      } else {
        this.headerInfo.setAttribute("style", "top: 0px");
        this.headerInfo.parentNode.setAttribute("style", "padding-bottom: 0px");
      }

    }
  }
}
</script>
