<template>
    <b-row v-if="seasons.length > 0">
        <b-col cols="12">
            <div class="seasons">
                <h3>Saisonzeiten</h3>
                <div class="season-wrapper">
                    <div v-for="(season,index) in seasons" v-bind:key="index" class="season">
                        <div class="season-inner">
                            <p class="season-headline">{{season.title}}</p>
                            <div v-for="(seasontime,index) in season.season" v-bind:key="index" class="seasontime">
                                <p>{{seasontime.time}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-col>
    </b-row>
</template>

<script>
    export default {
        name: "Seasons",
        props: ['theme'],

        data() {
            return {
                seasons: this.$store.state.fewoDatas[this.$props.theme][0].seasons,
            }
        }
    }
</script>