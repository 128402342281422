<template>
    <b-container class="location" id="location">
        <b-row align-v="center">
            <b-col cols="12" sm="6">
                <div class="locationinfo">
                    <h2>Lage & Anfahrt</h2>
                    <p v-html="locationinfo"></p>
                    <div class="address" v-html="address"></div>
                    <div class="routenplaner"><span class="icon-location"/> <a :href="mapslink" target="blank">Routenplanung starten</a></div>
                </div>
            </b-col>
            <b-col cols="12" sm="6" class="maps">
                <picture>
                    <img alt="Lage"
                         class="lazyload"
                         :src="require('~/assets/img/' + maps +'?resize').placeholder"
                         :data-src="require('~/assets/img/' + maps +'?resize').src"/>
                </picture>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: "Location",
        props: ['activeTheme'],
        data() {
            return {
                locationinfo: this.$store.state.fewoDatas[this.$props.activeTheme][0].locationinfo,
                address: this.$store.state.fewoDatas[this.$props.activeTheme][0].address,
                mapslink: this.$store.state.fewoDatas[this.$props.activeTheme][0].mapslink,
                maps: this.$store.state.fewoDatas[this.$props.activeTheme][0].maps
            }
        }
    }
</script>