<template>
    <div ref="slider">
        <div v-for="(picture,index) in gallery" :key="index" class="image-item">
            <picture>
                <source media="(min-width: 500px)" :data-srcset="require('~/assets/img/' + picture.img + '?size=768').src + ' 1x,' + require('~/assets/img/' + picture.img + '?resize').src + ' 2x'"/>
                <source media="(min-width: 320px)" :data-srcset="require('~/assets/img/' + picture.img + '?size=500').src + ' 1x, ' + require('~/assets/img/' + picture.img + '?size=1000').src + ' 2x'"/>
                <img class="lazyload" :data-src="require('~/assets/img/' + picture.img + '?size=768').src + ' 1x,' + require('~/assets/img/' + picture.img + '?resize').src + ' 2x'" :alt="picture.title"/>
            </picture>
        </div>
    </div>
</template>

<script>
    import { elementExisits } from '~/assets/js/utils';

    export default {
        name: "slider",
        props: ['theme','activeFewo'],

        data() {
            return {
                gallery: this.$store.state.fewoDatas[this.$props.theme][0].fewos[this.$props.activeFewo].gallery
            }
        },

        mounted() {
            this.$nextTick(() => {
                if (elementExisits(this.$refs.slider)) {
                    let module = require('tiny-slider/src/tiny-slider.module');

                    module.tns({
                        container: this.$refs.slider,
                        items: 1,
                        slideBy: 1,
                        mouseDrag: true,
                        loop: true,
                        preventScrollOnTouch: 'auto'
                    });
                }
            });
        }
    }
</script>