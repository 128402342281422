<template>
    <b-container class="contact" id="contact">
        <h2>Kontakt</h2>

        <b-row>
            <b-col cols="12" sm="6">
                <div class="contactinfos">
                    <div v-html="contactAddress"></div>
                    <p>E-mail: <a :href="'mailto:'+ contactEMail">{{contactEMail}}</a></p>
                    <bookingForm :selectTheme="selectTheme" :selectFewo="selectFewo"/>
                </div>
            </b-col>
            <b-col cols="12" sm="6">
                <h4>{{contactHeadline}}</h4>
                <div class="iconlist">
                    <div v-for="(item,index) in contactInfos" v-bind:key="index" class="icon">
                        <picture>
                            <img class="lazyload" :data-src="'/img/' + item.icon" :alt="item.title"/>
                        </picture>
                        <p>{{item.title}}</p>
                    </div>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>

    import bookingForm from "~/components/content/forms/bookingForm.vue";

    export default {
        name: "Contact",
        props: ['activeTheme','activeFewo'],

        components: {
            bookingForm
        },

        data() {
            return {
                contactAddress: this.$store.state.fewoDatas[this.$props.activeTheme][0].contactAddress,
                contactEMail: this.$store.state.fewoDatas[this.$props.activeTheme][0].contactEMail,
                contactHeadline: this.$store.state.fewoDatas[this.$props.activeTheme][0].contactHeadline,
                contactInfos: this.$store.state.fewoDatas[this.$props.activeTheme][0].contactInfos,
                selectTheme: this.$props.activeTheme,
                selectFewo: this.$props.activeFewo,
            }
        }
    }
</script>