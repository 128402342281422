<template>
    <v-app ref="app">
        <v-dialog v-model="dialog" ref="dialogWrapper" :fullscreen="$vuetify.breakpoint.xsOnly" persistent max-width="850px">
        <template v-slot:activator="{ on }">
            <div class="btn btn-icon booking" v-on="on"><span class="icon icon-bed" />Buchungsanfrage</div>
        </template>
        <v-card v-if="!sendForm" ref="bookingForm" id="bookingForm">
            <v-card-title>
                <div class="errorMessage" v-if="showErrorMessage">
                    <h4>Es ist ein Fehler aufgetreten</h4>
                    <p>Das Formular konnte nicht gesendet werden. Bitte versuchen Sie es später noch einmal. Sollte der Fehler weiterhin auftreten, wenden Sie sich bitte an {{hp}}.</p>
                </div>
                <h2 class="bookingHeadline">Buchungsanfrage</h2>
            </v-card-title>
            <v-form ref="form">
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <h4>Ferienwohnung</h4>
                            <v-select ref="fewo" v-model="fewo" :rules="[rules.required('Geben Sie eine Wohnung an.')]" :error-messages="errorMessages" :items="fewoItems" :value="fewoValue" label="Bitte wählen Sie eine Wohnung" filled required></v-select>
                        </v-col>
                        <v-col cols="12">
                            <h4>Reiseinformationen</h4>
                        </v-col>
                        <v-col cols="12" class="peopleBox">
                            <p>Personen</p>
                            <v-btn text v-bind:class="{ active: activeButtonMinus === true }" height="56px" @click="people >= 1 ? people-- : people" class="removePeople"><span class="minus"></span></v-btn>
                            <v-text-field ref="people" v-model="peopleResult" :rules="[rules.numberGreaterThan('Geben Sie die Anzahl der Personen an.', 0)]" type="number" :error-messages="errorMessages" label="Personen" filled readonly required></v-text-field>
                            <v-btn text v-bind:class="{ active: activeButtonPlus === true }" height="56px" @click="people < 4 ?people++ : people" class="addPeople"><span class="plus"></span></v-btn>
                        </v-col>
                        <v-col cols="12">
                            <p>Reisedatum</p>
                            <v-menu v-model="menu" ref="menu" min-width="auto" :close-on-content-click="false">
                                <template v-slot:activator="{ on }">
                                    <v-text-field ref="dates" v-model="dateRangeText" :rules="[rules.required('Bitte geben Sie ein Datum an.'), rules.date('Biite geben Sie ein valid Datum an.|Bitte geben Sie einen Datum Zeitraum ein')]" label="Reisendatum" readonly filled v-on="on"></v-text-field>
                                </template>
                                <v-date-picker locale="de-de" v-model="dates" no-title range :min="today">
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                                    <v-btn text color="primary" @click="$refs.menu.save(dates)">OK</v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12">
                            <h4>Kontaktinformationen</h4>
                        </v-col>
                        <v-col cols="12">
                            <v-radio-group ref="salutation" v-model="salutation" :rules="[rules.required('Bitte wählen Sie eine Anrede aus.')]" :error-messages="errorMessages" row filled required>
                                <v-radio label="Herr*" value="Herr"></v-radio>
                                <v-radio label="Frau*" value="Frau"></v-radio>
                                <v-radio label="Divers*" value="Divers"></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field ref="firstname" v-model="firstname" :rules="[rules.required('Bitte füllen Sie das Feld aus.')]" :error-messages="errorMessages" label="Vorname*" filled required></v-text-field>
                            <v-text-field ref="name" v-model="name" :rules="[rules.required('Bitte füllen Sie das Feld aus.')]" :error-messages="errorMessages" label="Nachname*" filled required></v-text-field>
                            <v-text-field ref="street" v-model="street" :rules="[rules.required('Bitte füllen Sie das Feld aus.')]" :error-messages="errorMessages" label="Straße*" filled required></v-text-field>
                            <v-text-field ref="plz" type="Number" v-model="plz" :rules="[rules.required('Bitte füllen Sie das Feld aus.'), rules.zipcode('Bitte geben Sie eine gültige PLZ an.')]" :error-messages="errorMessages" label="PLZ*" filled required></v-text-field>
                            <v-text-field ref="city" v-model="city" :rules="[rules.required('Bitte füllen Sie das Feld aus.')]" :error-messages="errorMessages" label="Stadt*" filled required></v-text-field>
                            <v-text-field ref="phone" v-model="phone" :rules="[rules.required('Bitte füllen Sie das Feld aus.'), rules.phone('Bitte geben Sie eine valide Telefonnummer an.')]" :error-messages="errorMessages" label="Telefon*" filled required></v-text-field>
                            <v-text-field ref="mail" v-model="mail" :rules="[rules.required('Bitte füllen Sie das Feld aus.'), rules.email('Bitte geben Sie eine valide Email Adresse')]" :error-messages="errorMessages" label="Mail*" filled required></v-text-field>
                            <v-text-field ref="hp" v-model="hp" style="display: none;"></v-text-field>
                            <v-textarea ref="message" v-model="message" label="Nachricht" filled required></v-textarea>
                            <p>*Pflichtfeld</p>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
            <v-card-actions>
                <button class="btn send" ref="submitButton" @click="submit" :disabled="btnDisabled">Absenden</button>
                <button class="close" @click="dialog = false"><span class="icon-close"></span></button>
            </v-card-actions>
        </v-card>
        <v-card v-else ref="bookingThanks" id="bookingThanks">
            <v-card-title><h2 class="bookingHeadline">Buchungsanfrage</h2></v-card-title>
            <v-container>
                <h3>Vielen Dank für Ihre Anfrage.</h3>
                <p>Wir werden diese prüfen und senden Ihnen zeitnah eine Antwort mit Angaben zum endgültigen Mietpreis sowie den Reservierungsinformationen.</p>

                <p>Sollten Sie eine kurzfristige Buchung innerhalb von 24 Stunden beabsichtigen, empfehlen wir Ihnen, uns gern telefonisch unter 030/ 401 63 84 zu kontaktieren.</p>

                <p>Hinweis: Sie erhalten in den nächsten Minuten eine automatische E-Mail mit der Zusammenfassung Ihrer Buchungsanfrage. Diese ist keine verbindliche Reservierungsbestätigung.</p>

                <h3>Zusammenfassung Ihrer Reisedaten</h3>
                <p><strong>Wohnung:</strong> {{ fewo }}<br/>
                    <strong>Personen:</strong> {{ people }}<br/>
                    <strong>Reisedatum:</strong> {{dateRangeText}}<br/>

                <h3>Ihre Kontaktdaten</h3>
                <p><strong>Anrede:</strong> {{ salutation }}<br/>
                    <strong>Vorname:</strong> {{ firstname }}<br/>
                    <strong>Nachname:</strong> {{ name }}<br/>
                    <strong>Straße:</strong> {{ street }}<br/>
                    <strong>PLZ:</strong> {{ plz }}<br/>
                    <strong>Stadt:</strong> {{ city }}<br/>
                    <strong>Telefon:</strong> {{ phone }}<br/>
                    <strong>E-Mail:</strong> {{ mail }}</p>

                <p><strong>Nachricht:</strong> {{ message }}</p>
            </v-container>
            <v-card-actions>
                <button class="close" @click="closeThankYouPage"><span class="icon-close"></span></button>
            </v-card-actions>
        </v-card>
    </v-dialog>
    </v-app>
</template>

<script>
let validator = require('validator');

export default {
    props: ['selectTheme','selectFewo'],

    data() {
        return {
            dialog: false,
            btnDisabled: false,
            sendForm: false,
            showErrorMessage: false,
            validator,
            today: new Date().toISOString().slice(0,10),

            fewo: this.$props.selectFewo,
            fewos: this.$store.state.fewoDatas[this.$props.selectTheme][0].fewos,
            fewoItems: [],
            fewoValue: '',
            selectedFewo: null,

            menu: false,
            dates: [new Date().toISOString().slice(0,10), new Date().toISOString().slice(0, 10)],
            dateFormatted: [],

            column: null,
            row: null,

            formHasErrors: '',
            errorMessages: '',
            fewo: null,
            people: 0,
            salutation: null,
            firstname: null,
            name: null,
            street: null,
            plz: null,
            city: null,
            phone: null,
            mail: null,
            message: null,
            hp: '',

            rules: {
                // vuetify expects a method taking the value as only parameter
                // this function returns such a function
                required: (message) => {
                    return (value) => {
                        if (!value) return message;
                        return !this.validator.isEmpty(value) || message;
                    }
                },
                numberGreaterThan: (message, number) => {
                    return (value) => {
                        if (!value) return message;
                        return value > number || message;
                    }
                },
                email: (message) => {
                    return (value) => {
                        if (!value) return message;
                        return this.validator.isEmail(value) || message;
                    }
                },
                phone: (message) => {
                    return (value) => {
                        if (!value) return message;
                        return this.validator.isMobilePhone(value) || message;
                    }
                },
                zipcode: (message) => {
                    return (value) => {
                        if (!value) return message;
                        return (new RegExp(/^[0-9]{5}(?:-[0-9]{4})?$/)).test(value) || message;
                    }
                },
                date: (message) => {
                    return (value) => {
                        let messages = message.split('|');
                        if (!value) return messages[0];

                        let dates = value.split(' - ');
                        let d1 = this.revertFormatDate(dates[0])

                        if (dates.length === 1) {
                            let isValidDate = (new Date(d1) !== "Invalid Date") && !isNaN(new Date(d1));
                            if (isValidDate) return messages[1];
                            else return messages[0];
                        } else {
                            if (dates.length === 2) {
                                let d2 = this.revertFormatDate(dates[1])

                                return ((new Date(d1) !== "Invalid Date") && !isNaN(new Date(d1)) &&
                                    (new Date(d2) !== "Invalid Date") && !isNaN(new Date(d2))) || messages[0];
                            }
                        }
                    }
                }
            },

            activeButtonPlus: true,
            activeButtonMinus: false
        }
    },

    computed: {
        dateRangeText: {
            get() {
                let dates = this.reArrangeDates(this.dates);

                let res;
                if (dates.length === 1) {
                    res = this.formatDate(dates[0]);
                } else {
                    if (dates.length === 2) {
                        let dateFrom = this.formatDate(dates[0]);
                        let dateTo = this.formatDate(dates[1]);

                        res = `${dateFrom} - ${dateTo}`
                    }
                }

                return res;
            },
            set(value) {
                return value;
            }
        },
        bookingForm () {
            return {
                fewo: this.fewo,
                people: this.people,
                salutation: this.salutation,
                firstname: this.firstname,
                name: this.name,
                street: this.street,
                plz: this.plz,
                city: this.city,
                phone: this.phone,
                mail: this.mail,
                message: this.message,
                dates: this.dates
            }
        },
        peopleResult: {
            get() {
                this.people > 0 ? this.activeButtonMinus = true : this.activeButtonMinus = false
                return this.people;
            },
            set(value) {
                return value;
            }
        }
    },

    watch: {
        dates (val) {
            this.dateFormatted = [this.formatDate(val[0]), this.formatDate(val[1])];
        }
    },

    mounted() {
        this.setValueofSelectFewo();

        if(this.people >= 1) {
            this.activeButtonMinus = true;
        }

        this.domain = ['suedstrand', 'hausastrid', 'hausnina'].find((name) => {
            return this.$props.selectTheme.includes(name);
        })

        this.themeColor = this.$store.state.fewoDatas[this.$props.selectTheme][0].color;
    },

    methods: {

        reArrangeDates(dates) {
            let res;
            if(this.dates[1]){
                let date1 = new Date(this.dates[0]);
                let date2 = new Date(this.dates[1]);

                if (date1 < date2) {
                    res = [this.dates[0], this.dates[1]];
                } else {
                    res = [this.dates[1], this.dates[0]];
                }
            } else res = [this.dates[0]]

            return res;
        },

        setValueofSelectFewo() {
            for (var i = 0; i < this.fewos.length; i++) {
                this.fewoItems.push(this.fewos[i].title);

                if(this.fewos[i].title == this.fewo) {
                    this.selectedFewo = this.fewo;
                }
            }

            if(this.selectedFewo) {
                this.fewoValue = this.selectedFewo;
            }
        },

        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}.${month}.${year}`
        },

        revertFormatDate (date) {
            if (!date) return null

            const [day, month, year] = date.split('.')
            return `${year}/${month}/${day}`
        },

        parseDate (date) {
            if (!date) return null

            const [day, month, year] = date.split('.')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },

        closeThankYouPage () {
            this.dialog = false;
            this.sendForm = false;

            this.fewo = '';
            this.people = '';
            this.salutation = '';
            this.firstname = '';
            this.name = '';
            this.street = '';
            this.plz = '';
            this.city = '';
            this.phone = '';
            this.mail = '';
            this.message = '';
        },

        submit () {
            this.formHasErrors = false;

            Object.keys(this.bookingForm).forEach(f => {
                if (!this.$refs[f].validate(true)) {
                    this.formHasErrors = true;
                }
            })

            if (this.hp.length > 0) return;

            if(this.formHasErrors == false) { //sentry_environment
                let dates = this.reArrangeDates(this.dates);
                let t1 = new Date(dates[0]).valueOf();

                let t2;
                if (dates.length === 2) {
                    t2 = new Date(dates[1]).valueOf();
                }

                let data = {
                    people: this.people,
                    salutation: this.salutation,
                    firstname: this.validator.escape(this.firstname),
                    name: this.validator.escape(this.name),
                    street: this.validator.escape(this.street),
                    plz: this.validator.escape(this.plz),
                    city: this.validator.escape(this.city),
                    phone: this.validator.escape(this.phone),
                    mail: this.validator.escape(this.mail),
                    message: this.message ? this.validator.escape(this.message) : '',
                    fewo: this.fewo,
                    startDate: t1,
                    endDate: t2,
                    d: this.domain,
                    c: this.themeColor,
                    hp: this.hp
                }

                this.$refs.submitButton.classList.add('deaktive');
                this.btnDisabled = true;

                this.$axios.$post('/reservations', data, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }).then((response) => {
                    this.sendForm = true;
                }).catch((err) => {
                    this.showErrorMessage = true;
                    // Hier habe ich noch keine funktionierende Vue Alternative gefunden.
                    document.getElementsByClassName('v-dialog--active')[0].scrollTop = 0;
                    this.$refs.submitButton.classList.remove('deaktive');
                    
                    this.$sentry.captureException(err);
                }).finally(() => {
                    this.btnDisabled = false;
                });
            } else {
                document.getElementsByClassName('v-dialog--active')[0].scrollTop = 0;
            }
        }
    }
}
</script>
