<template>
  <footer>
    <div class="container">
      <div class="infotext" v-html="footer" />
      <div class="footer-nav">
        <NuxtLink to="/datenschutz">Datenschutz</NuxtLink>
        <NuxtLink to="/impressum">Impressum</NuxtLink>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      footer: null
    }
  },

  props: ['activeTheme'],

  mounted() {
    this.footer = this.$store.state.fewoDatas[this.$props.activeTheme][0].footer;
  }
}
</script>
