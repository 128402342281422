<template>
  <div id="fewo">
      <fewoheader activeTheme="suedstrand205-206" activeFewo="Südstrand Ferienwohnung 205"/>
      <main>
        <willkommen/>
        <fewos activeTheme="suedstrand205-206" headline="Ferienwohnungen"/>
        <prices activeTheme="suedstrand205-206" headline="Preise"/>
        <location activeTheme="suedstrand205-206"/>
        <contact activeTheme="suedstrand205-206" activeFewo="Südstrand Ferienwohnung 205"/>
      </main>
      <fewofooter activeTheme="suedstrand205-206"/>
  </div>
</template>

<script>
  import fewoheader from "~/components/page/header/main.vue";
  import fewofooter from "~/components/page/footer/main.vue";
  import willkommen from "../custom-components/willkommen.vue";
  import fewos from "~/components/content/fewos.vue";
  import prices from "~/components/content/prices.vue";
  import location from "~/components/content/location.vue";
  import contact from "~/components/content/contact.vue";

export default {
    components: {
        fewoheader,
        fewofooter,
        willkommen,
        fewos,
        prices,
        location,
        contact
    },

    head() {
        return {
            title: "Ferienwohnungen Wohnung 205/206",
            meta: [
                { name: "description", content: "Wunderschöne Ferienwohnungen am Südstrand der Insel Fehmarn. Beide Wohnungen sind für 1-4 Personen geeignet und verfügen über einen Ostseeblick." },
                { name: "msapplication-TileColor", content: "#ffffff" },
                { name: "msapplication-TileImage", content: "Ferienwohnungen Wohnung 205/206" },
                { name: "theme-color", content: "#ffffff" }
            ]
        }
    }

}
</script>
