<template>
    <b-container class="fewos">
        <h2>{{ fewoHeadline }}</h2>
        <div v-for="(fewo,index) in fewos" v-bind:key="index" class="fewo">
            <div class="fewo-imageslider" v-if="!isTablet">
                <div class="">
                    <slider :theme="theme" :activeFewo="index"/>
                </div>
            </div>
            <lightbox :theme="theme" :activeFewo="index" v-if="isTablet"/>
            <div class="fewo-infos">
                <div class="descriptionHeader">
                    <h3 class="big">{{fewo.title}}</h3>
                    <div class="infotext">
                        <p>
                            <span class="qm"><span class="icon-area"></span>{{fewo.qm}}</span>
                            <span class="person"><span class="icon-person"></span>{{fewo.person}}</span>
                            <span class="pricePerNight">ab {{fewo.price}} / Nacht</span>
                        </p>
                    </div>
                </div>
                <input type="checkbox" :id="'show-description-wrapper' + index" class="show-description-wrapper"/>
                <label :for="'show-description-wrapper' + index"><p class="btn">Beschreibung und Ausstattung <span class="icon-accordion"></span></p></label>
                <div class="description-wrapper">
                    <input type="checkbox" :id="'show-description' + index" class="show-description"/>
                    <div class="description" v-html="fewo.description"></div>
                    <label :for="'show-description' + index"><p class="btn">Beschreibung und Ausstattung <span class="icon-accordion"></span></p></label>
                    <div class="price"><p>ab <span>{{fewo.price}}</span> / Nacht</p></div>
                    <bookingForm :selectTheme="theme" :selectFewo="fewo.title"/>
                </div>
            </div>
        </div>
    </b-container>
</template>

<script>
    import bookingForm from "~/components/content/forms/bookingForm.vue";
    import slider from "~/components/content/fewoSlider/slider.vue";
    import lightbox from "~/components/content/fewoLightbox/lightbox.vue";
    import { checkForTablet } from '~/assets/js/utils';

    export default {
        name: "Fewos",
        props: ['activeTheme','headline'],

        components: {
            slider,
            lightbox,
            bookingForm
        },

        // computed: {
        //     domain: function(){
        //         return ['suedstrand', 'hausastrid', 'hausnina'].find(function(name){
        //             return this.$props.activeTheme.contains(name);
        //         })
        //     }
        // },

        data() {
            return {
                isTablet: false,
                fewoHeadline: this.$props.headline,
                fewos: this.$store.state.fewoDatas[this.$props.activeTheme][0].fewos,
                theme: this.$props.activeTheme
            }
        },

        mounted() {
            let _scope = this;

            this.handleResponsiveBehaviour();

            window.addEventListener('resize', function () {
                setTimeout(function () {
                    _scope.handleResponsiveBehaviour();
                }, 100);
            });
        },

        methods: {
            handleResponsiveBehaviour () {
                this.isTablet = checkForTablet();
            }
        }

    }
</script>