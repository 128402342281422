<template>
    <b-container class="prices" id="prices">
        <b-row>
            <b-col cols="12">
                <h2>{{preisHeadline}}</h2>
                <div class="price-wrapper">
                    <div v-for="(price,index) in prices" v-bind:key="index" class="price">
                        <div class="price-inner">
                            <h4>{{price.title}}</h4>
                            <p><span>Personen:</span><span>{{price.person}}</span></p>
                            <p><span>Preis pro Nacht:</span><span><strong>{{price.priceNight}}</strong></span></p>
                            <p><span>Endreinigung:</span><span>{{price.cleaning}}</span></p>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>

        <season :theme="theme"/>

        <b-row>
            <b-col cols="12" md="9">
                <div class="priceinfo" v-html="priceinfo"></div>
            </b-col>
        </b-row>

    </b-container>
</template>

<script>
    import season from "~/components/content/season.vue";

    export default {
        name: "Fewos",
        props: ['activeTheme','headline'],

        components: {
            season
        },

        data() {
            return {
                theme: this.$props.activeTheme,
                preisHeadline: this.$props.headline,
                prices: this.$store.state.fewoDatas[this.$props.activeTheme][0].fewos,
                priceinfo: this.$store.state.fewoDatas[this.$props.activeTheme][0].priceinfo
            }
        }
    }
</script>